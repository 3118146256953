import axios from 'axios';
import { ENV } from 'src/env';

const axiosInstance = axios.create({ baseURL: ENV.BACKEND_URL, timeout: 2400000 });

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log(error);
        return Promise.reject((error.response && error.response.data) || 'Something went wrong');
    }
);

export default axiosInstance;
